import Vue from 'vue'
import VueRouter from 'vue-router'
import Guard from '../services/middleware'
import {valuationPermission} from '../services/check'
import Cookie from 'js-cookie'

Vue.use(VueRouter)

async function checkPermission(to, from, next) {
  let permission = await valuationPermission(to.params.id)
  if(permission) next()
  else next("/")
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      navbar: true,
    }
  },
  {
    path: '/avaliacao/:id',
    name: 'Valuation',
    component: () => import('../views/Valuation.vue'),
    beforeEnter: checkPermission,
    meta: {
      navbar: true
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
    meta: {
      navbar: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let userLogin = Cookie.get('_user_token')
  switch (to.path) {
    case '/test':
      next()
      break
    case '/login':  
      userLogin ? next({ path: '/', query: to.query }) : next()
      break
    default:
      userLogin ? next() : next({ path: '/login', query: to.query })
      break
  }
})

export default router
