const axios = require('axios')

async function confirmEmail(email){
    let url = `https://5ox4eal9fk.execute-api.us-east-1.amazonaws.com/dev/${email.toLowerCase()}`
    let res = 0
    try {
        let req = await axios.get(url)
        res = req.data
    } catch (error) {
        console.log(error)
    }
    return res
}

async function finishValuation(email){
    let url = `https://5ox4eal9fk.execute-api.us-east-1.amazonaws.com/dev/${email.toLowerCase()}`
    let res = 0
    try {
        let req = await axios.get(url)
        res = parseInt(req.data)
        res = (res - 1).toString()
        await axios.post(url, res)
        res = true
    } catch (error) {
        console.log(error)
        res = false
    }
    return res

}
module.exports = {confirmEmail, finishValuation}