import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

import Cookie from 'js-cookie'

import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

// Config environments
const config = {
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  endPointGraphQL: process.env.VUE_APP_ENDPOINT_GRAPHQL,
  endPointGraphQLWS: process.env.VUE_APP_ENDPOINT_GRAPHQL_WS
}

const authLink = setContext(async (_, { headers }) => {
   let token = Cookie.get('_user_token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = new HttpLink({ uri: config.endPointGraphQL })


const wsLink = new WebSocketLink({
  uri: config.endPointGraphQLWS,
  options: {
    reconnect: true,
  },
})



const link = split(
  // Split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

Vue.use(VueApollo)

export default apolloProvider
