<template>
    <v-app-bar app color="primary" style="z-index: 100;" flat>
      <div @click="open()" class="d-flex align-center">
        <v-img
          alt="BuyCo Logo"
          class="shrink mt-1 ml-n7"
          contain
          src="https://s3.amazonaws.com/buyco.assets/images/newLogoWhite.png"
          width="12em"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="logado" rounded elevation="0" dark color="#FFFFFF05" class="mx-1 text-capitalize" @click="logout()"> Deslogar </v-btn>
      <v-btn rounded elevation="0" dark color="#FFFFFF05" class="mx-1 text-capitalize" @click="whats()"> Ajuda </v-btn>
    </v-app-bar>
</template>
<script>
import Cookie from 'js-cookie'

export default({
    data() {
      return {
        logado: false,
      }
    },
    methods: {
      logout(){
        Cookie.remove('_user_token');
        Cookie.remove('_user_id');
        this.logado = false
        this.$router.push("/login")
      },
      whats() {
        window.open(
          `https://api.whatsapp.com/send?phone=5531972284773`,
          "_blank"
        );
      },
      open() {
        window.open("https://www.buyco.com.br/blog/", "_blank");
      },
    },
    mounted() {
      this.logado = !!Cookie.get('_user_token')
      setInterval(()=>{
        this.logado = !!Cookie.get('_user_token')
      },5000)
    },
})
</script>
