import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import { store } from './store/store';
import money from 'v-money';
import apolloProvider from './vue-apollo';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import isFlagActive from './plugins/featureFlag';
import VueGtag from 'vue-gtag';

Vue.use(PerfectScrollbar)
Vue.use(VueTheMask);
Vue.config.productionTip = false
Vue.use(money, {precision: 4})
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG }
});

// Add isFlagActive as a global parameter
Vue.prototype.$isFlagActive = async (flag) => await isFlagActive(flag, Vue.prototype.$gtag.event)

new Vue({
  router,
  store, 
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
