import Cookie from 'js-cookie';

export default {
    auth(to, from, next){

        const token = Cookie.get('_user_token'); //VERIFICA SE O TOKEN EXISTE, MAS NAO VEREFICA A AUTENTICIDADE DO TOKEN
        //TODO: AJAX PARA O BACK END PARA CHECAR TOKEN
        if (!token){
            next('/login');
        }
        next();
    },
}