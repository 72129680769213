import Vue from 'vue';
import Vuex from 'vuex';

import CNPJConfirmService from '../../services/CNPJConfirmService'
import calcularAPIService from '../../services/calcularAPiServicee'
import { finishValuation } from "../../services/emailConfirmation";

Vue.use(Vuex);


const state = {
	disabled: false,
	componentsInit: 0,
    stepCommand: '',
    phone: '',
}
const getters = {
}
const actions = {
	async empresaData(store, payload) {

		const CNPJMask = payload.CNPJ
		let cnpj = payload.CNPJ.replace(/[^\d]+/g, '')

		if (cnpj.length === 14) {
			try {
				const dataCNPJ = await CNPJConfirmService.get(cnpj)
	
				cnpj = CNPJMask
				store.commit('empresaDataSelectSet', { ...dataCNPJ.data, cnpj })
			} catch (error) {
				console.error('Get CNPJ', 'Error')	
				store.commit('empresaDataSelectSet', {getCNPJerror: error.response.status})
				store.commit('empresaDataSelectSet', {disabled: true})
			}
			store.commit('empresaDataSelectSet', {nextButtonLoading: false})

		}
	},
	empresaDataSelect(store, payload) {
		store.commit('empresaDataSelectSet', payload)
	},
	empresaClearData(store, payload) {
		store.commit('empresaClearData', payload)
	},
	companyData(context, payload) {
		context.commit('companyData', payload)
	},

	async sendData(context, payload) {		
		try {
			const res = await calcularAPIService.post(payload)
			context.commit('empresaDataSelectSet', {statusCode: res.status})
			await finishValuation(this.state.empresa.email)
			
		} catch (error) {
			console.error('Ocorreu um Erro no envio dos dados', error)
			context.commit('empresaDataSelectSet', {statusCode: 400})
		}
	},
	changeFinalStatusCode(context, payload){
		context.commit('empresaDataSelectSet',  {statusCode: payload})
	}
}
const mutations = {
	companyData(context, payload) {		
		this.state.empresa.company = { ...this.state.empresa.company, ...payload }
	},
	empresaClearData() {
		this.state.empresa = {}
	},
	empresaDataSelectSet(context, payload) {
		this.state.empresa = { ...this.state.empresa, ...payload }	
	}
}
export default {
	state,
	getters,
	actions,
	mutations
}