
import gql from "graphql-tag";
import { apolloClient } from '@/vue-apollo'

export let valuationPermission = async (id) => {
    try {
        let query = await apolloClient.query({
            query: gql`
                query MyQuery($id: uuid!) {
                    digital_valuation_information(where: {id: {_eq: $id}}) {
                        id
                        pdf_url
                    }
                }
            `,
          variables:
          {
              id: id
          },
          fetchPolicy: 'network-only'
        });
        let permission = false
        if(query.data.digital_valuation_information.length > 0) permission = !query.data.digital_valuation_information[0].pdf_url
        return permission
    } catch (error) {
        console.log('error: ', error)
        return false
    }
}
