<template>
    <v-app id="app">
        <meta name="viewport" content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, target-densitydpi=device-dpi">
        <v-main app>
            <BuycoAppBar/>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import BuycoAppBar from "@/components/BuycoApp/BuycoAppBar";

export default {
    name: "App",

    components: {
        BuycoAppBar,
    },

    data: () => ({
    }),
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#app2 {
    background-color: white;
}

#app {
    background-color: white;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
}

.backColor {
    background: #e4e9ed;
}

.input {
    background-color: #e4e9ed;
    width: 100%;
    height: 50px;
    border-radius: 4px;
}
</style>
