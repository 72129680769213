import Vue from 'vue'
import Vuex from 'vuex'
import empresa from './modules/empresa'

Vue.use(Vuex);

export const store = new Vuex.Store({
	modules: {
		empresa,		
	}
})