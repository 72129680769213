import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
        },
      themes: {
        light: {
          primary: "#343c59",
          secondary: '#E85D75',
          yellowBuyco: "#FFC107",
          greenCo: "#548687",
          accent: "#E4E9ED",
          error: "#FF5252",
          info: "#4B69A7",
          success: "#4CAF50",
          warning: "#FFC107"
        },
      },
    },
});
